@import "../node_modules/bootstrap/less/bootstrap.less";

@brand-primary: #aa2421;
@basic-text-color: black;

@less-important-text-color: lighten(@basic-text-color, 60%);
@less-hr-color: lighten(@basic-text-color, 75%);

@font-family-sans-serif: Roboto, Verdana, Tahoma, 'Avant Garde', "Helvetica Neue", Helvetica, sans-serif;
@font-family-serif: "EB Garamond", "Adobe Garamond Pro", Garamond, Georgia, "Times New Roman", Times, serif;
@font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base: @font-family-sans-serif;

@headings-font-family: @font-family-serif;

@headline-height: 60px;
@headline-vertical-padding: 10px;

@widget-divider-height: 20px;

@nav-link-color: @basic-text-color;
@nav-active-color: @brand-primary;
@nav-hover-color: @less-important-text-color;
@nav-font-size: 120%;

@footer-font-size: 100%;

@input-border-focus: @brand-primary;

@fa-font-path: '../../../font-awesome/fonts';
@fa-version: '4.7.0';

@font-face {
    font-family: 'FontAwesome';
    src: url('@{fa-font-path}/fontawesome-webfont.eot?v=@{fa-version}');
    src: url('@{fa-font-path}/fontawesome-webfont.eot?#iefix&v=@{fa-version}') format('embedded-opentype'),
    url('@{fa-font-path}/fontawesome-webfont.woff2?v=@{fa-version}') format('woff2'),
    url('@{fa-font-path}/fontawesome-webfont.woff?v=@{fa-version}') format('woff'),
    url('@{fa-font-path}/fontawesome-webfont.ttf?v=@{fa-version}') format('truetype'),
    url('@{fa-font-path}/fontawesome-webfont.svg?v=@{fa-version}#fontawesomeregular') format('svg');
    // src: url('@{fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
    font-weight: normal;
    font-style: normal;
}

body {
    -webkit-font-smoothing: antialiased;
}

#headline {
    padding-top: @headline-vertical-padding;
    padding-bottom: @headline-vertical-padding;
    font-family: @font-family-serif;

    #logo {
        height: @headline-height;
        padding-bottom: @headline-vertical-padding;
    }

    #slogan {
        font-size: @nav-font-size;
        font-style: italic;
        color: @less-important-text-color;
    }

    nav {
        line-height: 1;

        .caret {
            margin-left: 6px;
        }

        ul {
            &:not(.dropdown-menu) {
                display: inline-block;
                margin: 0 auto;
            }

            li {
                > a:hover {
                    background-color: inherit;
                    border-bottom: solid 2px @nav-hover-color;
                    border-radius: 0;
                }

                > a:focus {
                    background-color: inherit;
                }

                li > a:hover {
                    border: none;
                    background-color: @nav-hover-color;
                    color: white;
                }

                &.active > a {
                    background-color: inherit;
                    border-bottom: solid 2px @nav-active-color;
                    border-radius: 0;
                }

                li.active > a {
                    border: none;
                    background-color: @nav-active-color;
                    color: white;
                }
            }

            a {
                border: none;
                border-bottom: solid 2px transparent;
                color: @nav-link-color;
                font-size: @nav-font-size;
            }

            li li a {
                border-bottom-width: 0;
            }
        }
    }

}

#footer {
    padding-top: @headline-vertical-padding;
    padding-bottom: calc(3 * @headline-vertical-padding);

    font-family: @font-family-serif;
    font-size: @footer-font-size;

    text-align: justify;

    #mini-logo {
        height: 0.8em;
        vertical-align: middle;
        padding-bottom: 2px;
    }

    a {
        color: @basic-text-color;
    }
}

hr {
    border: none;
    border-bottom: solid 1px @basic-text-color;

    &.short-line {
        border-color: @less-hr-color;
        width: 70%;
    }
}

.full-width-form {
    width: 100%;
    margin-bottom: @widget-divider-height;
}

aside#sidebar {
    margin-top: 10px;
}

.post {
    margin-bottom: @headline-vertical-padding;

    &.post-mini {
        .post-title {
            font-size: 20px;
        }
    }

    .post-date {
        font-style: italic;
        font-family: @font-family-serif;
        text-align: center;
        padding-top: 22px;
        line-height: 1.1;

        .post-day-month {
            font-size: 1.6em;
            font-weight: bold;
        }

        .post-dow {
            font-size: 1.4em;
            font-weight: bold;
        }

        .post-year {
            font-size: 1.2em;
            color: @less-important-text-color;
        }
    }

    .post-title {
        font-weight: bold;
    }

    .post-title a {
        color: @basic-text-color;
    }

    .post-content {
        text-align: justify;

        .post-more {
            text-align: right;

            span {
                &:after {
                    content: '\25b6';
                    padding-left: .5em;
                }
            }

            a {
                color: @basic-text-color;
            }
        }
    }
}

.table {
    text-align: left;
}

.navigation {
    text-align: center;
    margin-top: @headline-vertical-padding;

    .wp-paginate {
        li span.current {
            background-color: @brand-primary;
            border-color: @brand-primary;
            color: white;
        }
    }
}

@font-anchor-size: 120%;

article {
    text-align: justify;

    a {
        font-family: @font-family-serif;
        font-size: @font-anchor-size;
    }
}

.post .post-content, .archive-link, .post-meta, .post-after-content, #sidebar {
    a {
        font-family: @font-family-serif;
        font-size: @font-anchor-size;
    }
}

.post .post-content {
    div,p,img {
        max-width: 100%;
        width: 100%;
        height: 100%;
    }
}

article ul, .post .post-content ul {
    list-style-type: none;
    padding-left: 1.5em;
    margin-left: 0;

    > li:before {
        display: inline-block;
        content: '\2014';
        width: 1.5em;
        margin-left: -1.5em;
    }
}

.anchor-list {
    list-style: none;
    margin: 0;
    padding: 0;
    float: right;

    li {
        float: left;
        padding-left: 16px;

        &:first-of-type {
            padding-left: 0;
        }
    }

    .clearfix();
}

a img.cryptxImage {
    vertical-align: middle;
    padding-bottom: 4px;
}

.breadcrumb {
    font-family: @font-family-serif;
    font-size: 120%;
}

.post-meta {
    margin: 1em 0;
    padding: 0;
    list-style: none;

    li {
        list-style: none;
    }

    .post-anchors {
        &:before {
            display: inline-block;
            content: "\f08e";
            font-family: FontAwesome;
            min-width: 25px;
        }
    }

    .post-category {
        &:before {
            display: inline-block;
            content: "\f07b";
            font-family: FontAwesome;
            min-width: 25px;
        }
    }

    .post-tags {
        &:before {
            display: inline-block;
            content: "\f02c";
            font-family: FontAwesome;
            min-width: 25px;
        }
    }
}

#sidebar {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    h2.widgettitle {
        font-size: 1.6em;
        font-weight: bold;
    }

    input[type="search"] {
        background: url('../../../../images/search.png') no-repeat scroll 94% center #fff;
        -moz-box-sizing: border-box;
        border: 1px solid #ccc;
        color: #808080;
        display: block;
        font: 12px @font-family-base;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
        padding: 10px 14px;
        width: 100%;
        -webkit-appearance: none !important;
        webkit-transition: all 0.15s ease-in-out;
        -moz-transition: all 0.15s ease-in-out;
        -o-transition: all 0.15s ease-in-out;

        &::-webkit-search-cancel-button {
            -webkit-appearance: none
        }

        &::-webkit-search-decoration {
            display: none
        }

        &:focus {
            outline: none;
            color: #505050;
        }
    }
}

.screen-reader-text, .wp-paginate .title {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
}
